
// import { useState } from "react";

// export const [audioBlob, setAudioBlob] = useState<Blob | null>(null);

// const isMediaRecorderSupported = () => {
//     return typeof MediaRecorder !== 'undefined';
//   };

// let mediaRecorder: MediaRecorder | null = null;

// const startRecordingWithMediaRecorder = async (): Promise<Blob> => {
//     return new Promise<Blob>(async (resolve, reject) => {
//         try {
//             const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//             mediaRecorder = new MediaRecorder(stream);
//             const chunks: Blob[] = [];

//             mediaRecorder.ondataavailable = (event: BlobEvent) => {
//                 chunks.push(event.data);
//             };

//             mediaRecorder.onstop = () => {
//                 const blob = new Blob(chunks, { type: 'audio/mp4' });
//                 setAudioBlob(blob);
//                 resolve(blob);
//             };

//             mediaRecorder.onerror = (event: any) => {
//                 reject(new Error(`Recording error: ${event.error?.name || 'unknown error'}`));
//             };

//             mediaRecorder.start();
//         } catch (error: any) {
//             reject(new Error(`Failed to access the microphone: ${error.message}`));
//         }
//     });
// };

// const stopRecordingWithMediaRecorder = () => {
//     if (mediaRecorder && mediaRecorder.state === 'recording') {
//         mediaRecorder.stop();
//     }
// };

// let audioContext: AudioContext | null = null;
// let processorNode: ScriptProcessorNode | null = null;
// let mediaStreamSource: MediaStreamAudioSourceNode | null = null;
// let audioData: Float32Array[] = [];

// const startRecordingWithScriptProcessor = async () => {
//   try {
//     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//     audioContext = new AudioContext();
//     processorNode = audioContext.createScriptProcessor(4096, 1, 1);
//     mediaStreamSource = audioContext.createMediaStreamSource(stream);

//     processorNode.onaudioprocess = (event) => {
//       const inputBuffer = event.inputBuffer.getChannelData(0);
//       audioData.push(new Float32Array(inputBuffer)); // Almacena los datos de audio
//     };

//     mediaStreamSource.connect(processorNode);
//     processorNode.connect(audioContext.destination);  // Evita que el audio se reproduzca

//     console.log('Recording started with ScriptProcessor');
//   } catch (err) {
//     console.error('Error accessing microphone:', err);
//   }
// };

// const stopRecordingWithScriptProcessor = () => {
//   if (processorNode && audioContext) {
//     processorNode.disconnect();
//     mediaStreamSource?.disconnect();
//     audioContext.close().then(() => {
//       console.log('Recording stopped with ScriptProcessor');
//       const buffer = mergeBuffers(audioData);  // Combina los arrays de audio
//       const audioBlob = sendToWorker(buffer);  // Envia los datos al worker para procesamiento (MP3, WAV, OGG)
//       audioData = [];
//       return audioBlob;
//     });
//   }
// };

// const mergeBuffers = (audioData: Float32Array[]) => {
//   const length = audioData.reduce((acc, buffer) => acc + buffer.length, 0);
//   const result = new Float32Array(length);
//   let offset = 0;
//   for (const buffer of audioData) {
//     result.set(buffer, offset);
//     offset += buffer.length;
//   }
//   return result.buffer;  // Retorna un ArrayBuffer para los workers
// };

// const mp3Worker = new Worker(new URL('./workers/encoder-mp3-worker.js', import.meta.url), { type: 'module' });

// const sendToWorker = (buffer: ArrayBuffer) => {
//     // Usamos el worker de MP3
//     mp3Worker.postMessage(['init', { baseUrl: window.location.origin, sampleRate: 44100 }]); // Puedes ajustar el sampleRate según sea necesario
//     mp3Worker.postMessage(['encode', buffer]);
//     mp3Worker.postMessage(['dump']);
  
//     mp3Worker.onmessage = (e) => {
//       const mp3Data = e.data;
//       const mp3Blob = new Blob(mp3Data, { type: 'audio/mp3' });
//       const url = URL.createObjectURL(mp3Blob);
//       console.log('MP3 URL:', url);
//       return mp3Blob;
//       // Aquí puedes usar el URL para descargar el archivo o enviarlo a un servidor
//     };
//   };

//   export const recordAudio = () => {
//     if (isMediaRecorderSupported()) {
//       startRecordingWithMediaRecorder();
//     } else {
//       startRecordingWithScriptProcessor();
//     }
//   };
  
//   export const stopRecording = () => {
//     if (isMediaRecorderSupported()) {
//       stopRecordingWithMediaRecorder();
//     } else {
//       stopRecordingWithScriptProcessor();
//     }
//   };

// F U N C I O N A L

// let mediaRecorder: MediaRecorder | null = null;

// export const recordAudio = async (): Promise<Blob> => {
//     return new Promise<Blob>(async (resolve, reject) => {
//         try {
//             const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//             mediaRecorder = new MediaRecorder(stream);
//             const chunks: Blob[] = [];

//             mediaRecorder.ondataavailable = (event: BlobEvent) => {
//                 chunks.push(event.data);
//             };

//             mediaRecorder.onstop = () => {
//                 const blob = new Blob(chunks, { type: 'audio/wav' });
//                 resolve(blob);
//             };

//             mediaRecorder.onerror = (event: any) => {
//                 reject(new Error(`Recording error: ${event.error?.name || 'unknown error'}`));
//             };

//             mediaRecorder.start();
//         } catch (error: any) {
//             reject(new Error(`Failed to access the microphone: ${error.message}`));
//         }
//     });
// };

// export const stopRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === 'recording') {
//         mediaRecorder.stop();
//     }
// };

const MicRecorder = require('mic-recorder-to-mp3');
let recorder: any;
let audioChunks: Blob[] = [];

export const recordAudio = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            recorder = new MicRecorder({bitRate: 128});
            await recorder.start();
        } catch (error: any) {
            console.log(error);
        }
}

export const pauseRecording = async () => {
    if (recorder) {
        try {
            const [buffer, blob] = await recorder.stop().getMp3(); // Usar async/await
            const file = new File(buffer, 'me-at-thevoice.wav', {
                type: 'audio/wav',
                lastModified: Date.now()
            });
            console.log("Recording paused");

            // Almacenar el fragmento grabado
            audioChunks.push(file);

            console.log("Audio chunk stored");

        } catch (e: any) {
            console.error("Error while stopping recording:", e);
        }
    }
};

export const resumeRecording = async () => {
    if (recorder) {
        try {
            recorder = new MicRecorder({ bitRate: 128 });
            await recorder.start(); // Iniciar nuevamente
            console.log("Recording resumed");
        } catch (e: any) {
            console.error("Error resuming recording:", e);
        }
    }
};

export const stopAndCombineAudio = async () : Promise<Blob> => {
    return new Promise<Blob>(async (resolve, reject) => {
        try {
            console.log("Audio stopped and combining");
            console.log(audioChunks.length);
            const combinedBlob = new Blob(audioChunks, { type: 'audio/wav' });
            const file = new File([combinedBlob], 'combined-audio.wav', {
                type: 'audio/wav',
                lastModified: Date.now()
            });
            console.log("Audio done");
            resolve(file);
        } catch (e: any) {
            reject(new Error("Error combining audio chunks"));
        }
        
    })
};

export const resetAudioChunks = () => {
    audioChunks = [];
    console.log("Chunks reseted");
}

export const stopRecording = async () : Promise<Blob> => {
    return new Promise<Blob>(async (resolve, reject) => {
        try {
        recorder
        .stop()
        .getMp3().then((buffer: any, blob: any) => {
            const file = new File(buffer, 'me-at-thevoice.wav', {
            type: 'audio/wav',
            lastModified: Date.now()
            });
            resolve(file);
        }).catch((e: any) => {
            alert('We could not retrieve your message');
            console.log(e);
        });
        } catch (error: any) {
            reject(new Error(`Failed to access the microphone: ${error.message}`));
        }
    })
}