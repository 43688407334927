// L I B R A R I E S 
import { Box, Button, Fade, Menu, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

// C O M P O N E N T S
import { textToSpeech } from './textToSpeech';
import { recordAudio, stopRecording, pauseRecording, resumeRecording, stopAndCombineAudio, resetAudioChunks } from './recordAudio';
import { Message } from '../models/Message';
import { User } from '../models/User';

// A S S E T S
import { Mic, ArrowUpwardRounded, Dehaze, CheckCircle, Cancel, PauseCircleOutline, PlayCircleFilledRounded, VolumeUp } from '@mui/icons-material';
import WaitingResponse from '../assets/waitingResponse';
import SoundWaves from '../assets/soundWave';
import Bot from '../assets/bot.png'
import '../components/Chat.css';
import '../App.css';

function Chat() {
  const location = useLocation();
  const language: string = location.state?.language;

  const firstMessage = language === 'english' ? [{role: 'user', content: 'I want to practice my English. Specifically, a conversation for beginner level A1. Ask me a question to start a conversation and tell me immediately when I have a grammar, or vocabulary mistake during this conversation. My native language is Spanish.'}]
    : language === 'german' ? [{role: 'user', content: 'Ich möchte mein Deutsch üben. Insbesondere ein Gespräch auf Anfängerniveau A1. Stelle mir eine Frage, um ein Gespräch zu beginnen, und sage mir sofort Bescheid, wenn ich während dieses Gesprächs einen Grammatik- oder Wortschatzfehler mache. Meine Muttersprache ist Spanisch.'}]
    : [{role: 'user', content: "Je veux pratiquer mon français. Plus précisément, une conversation pour le niveau débutant A1. Posez-moi une question pour commencer une conversation et dites-moi immédiatement lorsque j'ai une faute de grammaire ou de vocabulaire pendant cette conversation. Ma langue maternelle est l'espagnol."}];
  
  const [messages, setMessages] = useState<Message[]>(firstMessage);
  const [showSendIcon, setShowSendIcon] = useState(false);
  const currentUser: User = location.state?.currentUser;
  const [response, setResponse] = useState<string>('');
  const [input, setInput] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const [transcription, setTranscription] = useState<string | null>(null);
  const [isMicCanceled, setIsMicCanceled] = useState(false);
  const [isPausedBeggined, setIsPauseBeggined] = useState(false);
  const [isAudioPaused, setIsAudioPaused] = useState(false);
  const [consoleLogMessage, setConsoleLogMessage] = useState("");
  const [errorAppeared, setErrorAppeared] = useState(false);
  
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null); // Guardar el Blob de audio
  const [audioURL, setAudioURL] = useState<string | null>(null); // URL del Blob para reproducir

  const [isMicActivated, setIsMicActivated] = useState(false);
  const [isStopRecordingAvailable, setIsStopRecordingAvailable] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const isIOS = () => {
    return (
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream
    );
  };

  const isMacOS = (): boolean => {
    return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  };

  const isSafari = (): boolean => {
    return (
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
      isMacOS()
    );
  };

const handleSubmit = async (newInput: any) => {
  setConsoleLogMessage("Empieza handleSubmit");
  console.log("Input before submitting: ", transcription)
  setInput(''); 

  const userMessage = { role: 'user', content: newInput };
    const newMessages = [
      ...messages,
      userMessage
    ];

    setMessages(newMessages);

  try {
    const res = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-2024-08-06',
        messages: newMessages
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
        }
      }
    );

    const responseData = res.data;
    const assistantResponse = responseData.choices[0].message.content;
    setConsoleLogMessage("Se recibió respuesta de la API de OpenAI");

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'assistant', content: assistantResponse }
    ]);
    setResponse(assistantResponse);
    setShowSendIcon(false);
    console.log(messages);

    if (isMicActivated) {
      const audioBlobToSpeech = await textToSpeech(assistantResponse);
      setConsoleLogMessage("Se recibió audio de respuesta de API");
      if (audioBlobToSpeech) {
        setConsoleLogMessage("Log antes de que empiece playAudioWithPause");
        await playAudioWithPause(audioBlobToSpeech, assistantResponse);
        setConsoleLogMessage("Termina exitosamente playAudioWithPause y reinicia transcribeAudio");
        if (isMicActivated) await transcribeAudio();
      }
    }

  } catch (error) {
    console.error('Error fetching data from OpenAI API', error);
    setResponse("Lo siento, hubo un error. Intenta de nuevo más tarde.");
  }
};

  const playAudioWithPause = (audioBlobToPlay: Blob, assistantResponse: string): Promise<void> => {
    return new Promise((resolve) => {
      try {
        setConsoleLogMessage("Empieza playAudioWithPause");
        let audioUrl = "";
        let audio = new Audio();
        try {
          audioUrl = URL.createObjectURL(audioBlobToPlay);
          audio.src = audioUrl;
          setAudioURL(audioUrl);
          setConsoleLogMessage("Se creó correctamente el audio de respuesta");
        } catch (error) {
          setConsoleLogMessage("Error al crear audio: " + error);
        }
        
        // Resolver el Promise cuando el audio termine de reproducirse
        audio.onended = async () => {
          setConsoleLogMessage("Terminó de reproducirse el audio");
          console.log("Terminó de reproducirse");
          resolve();
        };
  
        // Iniciar la reproducción
        try {
          audio.play();            
          setConsoleLogMessage("Inicia a reproducirse el audio");
          const numberOfWords = assistantResponse.split(" ").length;
          const timeSpeaking = (numberOfWords / 3.33) * 1000;
          if (isIOS() || isSafari()) {
            setConsoleLogMessage("Estoy en un iPhone, palabras: " + numberOfWords + " , tiempo a hablar: " + timeSpeaking + " , respuesta: " + assistantResponse);
            setTimeout(() => {
              setConsoleLogMessage("No terminó el audio pero pasó el tiempo");
              resolve();
            }, timeSpeaking);
          } else {
            setConsoleLogMessage("No estoy en un iPhone, palabras: " + numberOfWords + " , tiempo a hablar: " + timeSpeaking + " , respuesta: " + assistantResponse);
          }
        } catch (error) {
          setConsoleLogMessage("Error al reproducir el audio");
          console.error("Error al reproducir el audio desde el principio", error);
          resolve();
        }
      } catch (error) {
        console.error("Error al reproducir el audio:", error);
        setConsoleLogMessage("Error al reproducir playAudioWithPause");
        resolve();
      }
    });
  };

  const transcribeAudio = async () => {
    if (Math.floor(messages.length / 2) >= messagesLimit) {
      setIsMicActivated(false);
      setIsStopRecordingAvailable(false);
      stopRecording();
    } else {
      setConsoleLogMessage("Empieza transcribeAudio");
      setIsStopRecordingAvailable(true);
      console.log("starting");
      recordAudio();
    }
  };

  useEffect(() => {
    const processAudio = async () => {
      setConsoleLogMessage("Empieza audioBlob useEffect");
      if (!audioBlob) {
        console.error("AudioBlob is null or undefined");
        return;
      }
      setConsoleLogMessage("Sí hay audioBlob");
      if (isMicActivated) {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav');
        formData.append('model', 'whisper-1');
    
        try {
          const response = await axios.post(
            'https://api.openai.com/v1/audio/transcriptions',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
              },
            }
          );
          setTranscription(response.data.text);
          setConsoleLogMessage("Se asigna valor a Transcription");
        } catch (error) {
          console.error('Error transcribing audio', error);
          setConsoleLogMessage("Error en audioBlob useEffect");
          setErrorAppeared(true);
          if(isPausedBeggined) {
            setIsPauseBeggined(false);
            setIsAudioPaused(false);
            resetAudioChunks();
            setIsMicActivated(false);
            setIsStopRecordingAvailable(false);
            setAudioBlob(await stopRecording());
          } else {
            setIsMicActivated(false);
            setIsStopRecordingAvailable(false);
            setAudioBlob(await stopRecording());
          }
        }
      }
    };
    processAudio();
  }, [audioBlob])

  useEffect(() => {
    if (transcription) {
      if (isMicActivated) {
        setConsoleLogMessage("Transcription useEffect empezó");
        handleSubmit(transcription);
      }
    }
  }, [transcription]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: 'gpt-4o-2024-08-06',
            messages: [
              ...messages]
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
            }
          }
        );
  
        const responseData = res.data;
        const assistantResponse = responseData.choices[0].message.content;
  
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'assistant', content: assistantResponse }
        ]);
        setResponse(assistantResponse);
        console.log(messages);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData(); 
  }, []);

  useEffect(() => {
    const startTranscription = async () => {
      if (isMicActivated) {
        console.log("Empieza el primer transcribeAudio");
        await transcribeAudio();
      }
    };

    if (isMicActivated) {
      startTranscription(); // Inicia la transcripción
      setConsoleLogMessage("IsMicActivated useEffect, ya empezó startTranscription");
    } 
  }, [isMicActivated]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const toogleTranscription = () => {
    setIsMicActivated((prev) => !prev);
    setIsMicCanceled(false);
    setConsoleLogMessage("Empieza Toggle Transcription");
  }

  if (!currentUser || !currentUser.isAuthenticated) {
    return <Navigate to="/login" />
  }

  const handleChange = (event: any) => {
    setInput(event.target.value);
    setShowSendIcon(event.target.value.length > 0);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePracticeConversation = () => {
    setAnchorEl(null);
    navigate('/chat', {state: {currentUser, language: language}})
  };

  const handleGrammar = () => {
      setAnchorEl(null);
      navigate('/grammar', {state: {currentUser, language: language}})
  };

  const handleTaskReviewer = () => {
    setAnchorEl(null);
    navigate('/taskreviewer', {state: {currentUser, language: language}})
    };

  const handleHome = () => {
    setAnchorEl(null);
    navigate('/home', {state: {currentUser, language: language}})
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const messagesLimit = 20;

  const responsiveness = (small: any, medium: any, large: any) => {
    return window.innerWidth < 600 ? small : window.innerWidth < 900 ? medium : large;
  }

  const playLastAudio = () => {
    if (audioURL === null) return;
    const audioToPlay = new Audio(audioURL);
    audioToPlay.play();
  };
  
  const isLimitReached = Math.floor(messages.length / 2) - 1 >= messagesLimit;
 
  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <nav style={{
            backgroundColor: '#514DD8',
            paddingLeft: '5%'
        }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', paddingTop: '0.75%', paddingBottom: '0.75%'}}>
                <Button sx={{ flex: 1 }} onClick={handleClick}>
                    <Dehaze fontSize='large' style={{ color: 'white' }} />
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem sx={{fontSize: responsiveness('12px', '14px', '16px')}} onClick={handleHome}>INICIO</MenuItem>
                    <MenuItem sx={{fontSize: responsiveness('12px', '14px', '16px')}} onClick={handlePracticeConversation}>QUIERO PRACTICAR MI CONVERSACIÓN</MenuItem>
                    <MenuItem sx={{fontSize: responsiveness('12px', '14px', '16px')}} onClick={handleGrammar}>TENGO UNA DUDA DE GRAMÁTICA O VOCABULARIO</MenuItem>
                    <MenuItem sx={{fontSize: responsiveness('12px', '14px', '16px')}} onClick={handleTaskReviewer}>QUIERO REALIZAR UNA ACTIVIDAD DEL CURSO</MenuItem>
                </Menu>
                <div style={{
                  flex: 20,
                  fontFamily: 'PublicSans-Bold', 
                  fontSize: responsiveness('20px', '26px', '30px'), 
                  display: 'flex', 
                  color: 'white', 
                  alignItems: 'center', 
                  justifyContent: 'center'
                }}>
                  Retzia <img src={Bot} alt='' width={responsiveness(50, 60, 70)} />                
                </div>
            </div>
        </nav>
      <Box sx={{
        display: 'flex',
        paddingTop: '2%',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'scroll'
      }}>
        {messages.map((message, index) => {
          return (
              <Box
              sx={{ display: 'flex', justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start', marginBottom: '1%'}}
              key={index}
              mb={1}
          >
              <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    bgcolor: message.role === 'user' ? '#1e8efc' : '#cccaca',
                    color: message.role === 'user' ? 'white' : 'black',
                    maxWidth: '70%',
                    borderRadius: '15px',
                    position: 'relative',
                    marginRight: responsiveness('2%', '2%', '2%'),
                    marginLeft: responsiveness('2%', '2%', '2%'),
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        width: 0,
                        height: 0,
                        border: '10px solid transparent',
                        borderTopColor: message.role === 'user' ? '#1e8efc' : '#cccaca',
                        top: '50%',
                        transform: 'translateY(-10%)',
                        right: message.role === 'user' ? '-10px' : 'auto',
                        left: message.role === 'user' ? 'auto' : '-10px',
                    },
                }}
              >
             { index === 0 ? <Typography sx={{ fontSize: responsiveness('14px', '14px', '16px')}}>{language === 'english' ? 'I want to practice my conversations in English...' 
                                            : language === 'german' ? 'Ich möchte meine Konversationen auf Deutsch üben ...'
                                            : 'Je veux pratiquer mes conversations en français...'}</Typography>
                : <Typography sx={{ fontSize: responsiveness('14px', '14px', '16px')}}>{message.content}</Typography>
              }
              </Paper>
          </Box>
            ); })}
            <div ref={messagesEndRef} />
            { audioURL !== null && (isIOS() || isSafari()) && (
              <Button 
              sx={{
                alignSelf: 'start',
                marginLeft: '2%',
                marginTop: '-1%'
              }}
              onClick={() => {
                playLastAudio();
              }}>
                <VolumeUp fontSize='small' />
              </Button>
            )}
      </Box>
      {  errorAppeared && (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', fontSize: '20px'}}>Ocurrió un error, favor de volver a intentarlo.</div>
      )}
      { isLimitReached && !isMicActivated && (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', fontSize: '20px'}}><a href="https://retz.com.mx/feedback-retzia/">¿Cómo fue tu experiencia en esta conversación? Ingresa aquí para ayudarnos a mejorar..</a></div>
      )}
      {/* <div style={{ display: 'flex', width: '100%', justifyContent: 'center', fontSize: '20px'}}>{consoleLogMessage}</div> */}
      { isStopRecordingAvailable && !isMicCanceled && !isAudioPaused && (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', fontSize: '20px'}}>escuchando...</div>
      )}
      { isStopRecordingAvailable && !isMicCanceled && isAudioPaused && isPausedBeggined && (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', fontSize: '20px'}}>en pausa...</div>
      )}
      { !isStopRecordingAvailable && isMicActivated && (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', fontSize: '20px'}}>respondiendo...</div>
      )}
      <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            backgroundColor: '#f8f8f8', 
            justifySelf: 'end',
            margin: '1% 2%',
            borderRadius: responsiveness('8px', '10px', '16px'),
            justifyContent: isLimitReached ? 'space-between' : ''
            }}>
            <TextField 
            onKeyDown={(event: any) => {
              if(event.key === 'Enter') {
                setErrorAppeared(false);
                handleSubmit(input);
                setAudioURL(null);
              }
            }}
            disabled={isLimitReached}
            value={input}
            InputProps={{ disableUnderline: true }} 
            variant='standard' 
            sx={{ 
              width: isLimitReached ? '0%' : !isMicActivated ? '95%' : '0%', 
              padding: '1%', 
              borderRadius: '16px',
              '& .MuiInputBase-input::placeholder': {
                fontSize: responsiveness('9px', '14px', '14px'), // Cambia el tamaño aquí
              },
            }} 
            onChange={handleChange}
            placeholder= {!isMicActivated ? 'Haz clic en el micrófono o escribe aquí.' : ''}>
            </TextField>
            { isLimitReached && !isMicActivated && (
              <Typography
              sx={{
                fontSize: responsiveness('12px', '18px', '24px'),
                alignContent: 'center',
                justifyContent: 'center'
              }}>
                Tus interacciones del día han finalizado, por favor, regresa mañana.
              </Typography>
            )}
            { isStopRecordingAvailable && !isMicCanceled && (
              <Fade in={isStopRecordingAvailable} timeout={500}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                  <Button sx={{ 
                            padding: 0,
                            minWidth: 'auto',
                            width: 'auto',
                            height: 'auto',
                            marginX: '0.5%'
                            }} 
                          onClick={ async () => {
                            if(isPausedBeggined) {
                              setIsPauseBeggined(false);
                              setIsAudioPaused(false);
                              resetAudioChunks();
                              setIsMicActivated(false);
                              setIsStopRecordingAvailable(false);
                              setAudioBlob(await stopRecording());
                            } else {
                              setIsMicActivated(false);
                              setIsStopRecordingAvailable(false);
                              setAudioBlob(await stopRecording());
                            }
                  }}>
                    <Cancel style={{ color: 'red' }} fontSize='large' />
                  </Button>
                  { !isAudioPaused && (
                    <Button sx={{
                    padding: 0,
                    minWidth: 'auto',
                    width: 'auto',
                    height: 'auto',
                  }}
                  onClick={async () => {
                    setIsPauseBeggined(true);
                    setIsAudioPaused(true);
                    await pauseRecording();
                  }}
                  >
                    <PauseCircleOutline fontSize='large' />
                  </Button>
                  )}
                  { isPausedBeggined && isAudioPaused && (
                    <Button sx={{
                    padding: 0,
                    minWidth: 'auto',
                    width: 'auto',
                    height: 'auto',
                  }}
                  onClick={() => {
                    setIsAudioPaused(false);
                    resumeRecording();
                  }}
                  >
                    <PlayCircleFilledRounded fontSize='large' />
                  </Button>
                  )}
                  <SoundWaves />
                  <Button sx={{ 
                            padding: 0,
                            minWidth: 'auto',
                            width: 'auto',
                            height: 'auto',
                            marginX: '0.5%'
                            }} 
                          onClick={ async () => {
                            if (isPausedBeggined) {
                              await pauseRecording();
                              setIsAudioPaused(false);
                              setIsPauseBeggined(false);
                              setAudioBlob(await stopAndCombineAudio());
                              setIsStopRecordingAvailable(false);
                              resetAudioChunks();
                            } else {
                              setAudioBlob(await stopRecording());
                              setIsStopRecordingAvailable(false);
                            }
                  }}>
                    <CheckCircle style={{ color: 'blue' }} fontSize='large' />
                  </Button>
                </div>
             </Fade>
            )}
            { !isStopRecordingAvailable && isMicActivated && (
              <Fade in={!isStopRecordingAvailable && isMicActivated} timeout={500}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                  <Button sx={{ 
                            padding: 0,
                            minWidth: 'auto',
                            width: 'auto',
                            height: 'auto',
                            marginX: '0.5%'
                            }} 
                          onClick={() => {
                            setIsMicActivated(false);
                            setIsStopRecordingAvailable(false);
                            setIsMicCanceled(true);
                  }}>
                    <Cancel style={{ color: 'red' }} fontSize='large' />
                  </Button>
                  <WaitingResponse />
                </div>
             </Fade>
            )}
            <Typography sx={{ alignContent: 'center', fontSize: responsiveness('12px', '14px', '16px'), marginX: '0.5%', marginRight: isLimitReached ? '2%' : '1%' }}>{Math.floor(messages.length / 2) - 1}/{messagesLimit}</Typography>
            { showSendIcon && (
            <Fade in={showSendIcon} timeout={500}>
              <Button 
                disabled={isLimitReached} 
                onClick={() => {
                  setErrorAppeared(false);
                  handleSubmit(input);
                  setAudioURL(null);
                }}
                sx={{
                  padding: 0,
                  minWidth: 'auto',
                  width: 'auto',
                  height: 'auto',
                  marginX: '0.5%'
                  }}
                >
                <ArrowUpwardRounded style={{ color: 'black'}} fontSize='large' />
              </Button>
            </Fade>
            )}
            {!isMicActivated && Math.floor(messages.length / 2) - 1 < messagesLimit && (
              <Button 
              sx={{ padding: 0,
                    minWidth: 'auto',
                    width: 'auto',
                    height: 'auto',
                    marginX: '0.5%'
              }} 
              onClick={() => {
                setErrorAppeared(false);
                toogleTranscription();
              }}
              >
              <Mic style={{ color: 'black'}} fontSize='large' />
            </Button>
            )}
          </Box>
    </div>
  );
};

export default Chat;